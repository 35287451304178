import React, { useEffect, useRef, useState } from 'react';
import ButtonLoader from '../Components/ButtonLoader';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import ButtonAdd from '../Components/ButtonAdd';
import Calendar from '../Components/Calendar';
import axiosInstance from '../Utils/axiosInstance';
import {
  BOOKING_ENDPOINT,
  LANE_SLOT_ENDPOINT,
  LIST_BAY_ENDPOINT,
  LIST_BAY_LANES_ENDPOINT,
  PARTICIPANT_ENDPOINT,
  PARTICIPANT_LIST_ENDPOINT,
} from '../Utils/Endpoints';
import { getItem } from '../Utils/LocalStorage';
import Logger from '../Utils/Logger';
import Toast from '../Utils/Toast';
import SlotForm from '../Components/SlotForm';
import { LANE_CLASS } from '../Utils/ModelClasses';
import moment from 'moment';
import LoadingOverlayMain from '../Components/LoadingOverlayMain';
import StripePayment from '../Components/Payment/StripePayment';
import TerminalComponent from '../Components/Payment/TerminalComponent';

const BookSlotUserAdd = () => {
  const [selected, setSelected] = useState('');
  const [selectedSlot, setSelectedSlot] = useState({});
  const [bayList, setBayList] = useState([]);
  const [laneList, setLaneList] = useState([]);
  const [slotList, setSlotList] = useState(null);
  const currentDate = new Date();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [generalError, setGeneralError] = useState('');
  const [participant, setParticipant] = useState({});
  const [sendParticipant, setSendParticipant] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [bayId, setBayId] = useState('');
  const [selectedBay, setSelectedBay] = useState({});
  const [laneId, setLaneId] = useState('');
  const rangeId = getItem('rangeId');
  const [selectedDate, setSelectedDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`
  );
  const location = useLocation();
  const selectedUser = location.state && location.state.user;
  const [activeTab, setActiveTab] = useState('cash');
  const [searchText, setSearchText] = useState(
    selectedUser?.user?.first_name ? selectedUser?.user?.first_name : ''
  );
  const [participantList, setParticipantList] = useState([]);
  const multiselectRef = useRef(null);
  const navigate = useNavigate();

  // Payment Ref
  const stripeRef = useRef(null); // To access the Stripe form in child component
  // const terminalRef = useRef(null);

  useEffect(() => {
    fetchBayList();
  }, []);

  const fetchBayList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(LIST_BAY_ENDPOINT + rangeId);
      if (response.data.code === 200) {
        setBayList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchParticipantList();
  }, [searchText]);

  const fetchParticipantList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        PARTICIPANT_LIST_ENDPOINT + `?search=${searchText}`
      );
      if (response.data.code === 200) {
        if (response.data.data.data.length > 0) {
          const responseArray = response?.data?.data?.data.map((item) => ({
            ...item,
            displayValue: item.name + `  (${item.phone_number})`,
          }));
          setParticipantList(responseArray);
        }
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeBay = async (id) => {
    try {
      setLaneId('');
      setLaneList([]);
      setSlotList(null);
      setSelectedSlot({});
      setSelected('');

      if (id === '') {
        setBayId('');
        setSelectedBay({});
        return;
      }
      setIsLoading(true);
      setBayId(id);
      setSelectedBay(bayList?.find((item) => item.id === Number(id)));
      const response = await axiosInstance.get(LIST_BAY_LANES_ENDPOINT + id);
      if (response.data.code === 200) {
        setLaneList(response?.data?.data?.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeLane = async (id) => {
    try {
      setSelectedSlot({});
      setSelectedUsers([]);
      setActiveTab('cash');
      setSelected('');
      if (id === '') {
        setLaneId('');
        setSlotList(null);
        return;
      }
      setIsLoading(true);
      setLaneId(id);
      const response = await axiosInstance.get(
        LANE_SLOT_ENDPOINT + id + `?date=${selectedDate}`
      );
      if (response.data.code === 200) {
        setSlotList(response?.data?.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setBayId('');
    setGeneralError('');
    setSelected('');
    setSelectedSlot({});
    setLaneId('');
    setBayList([]);
    setLaneList([]);
    setSlotList(null);
    setSelectedUsers([]);
    setSelectedDate(
      `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`
    );
  };

  // Handler when options are selected
  const onSelect = (selectedList) => {
    setSelectedUsers(selectedList);
    setActiveTab('cash');
    setSearchText('');
  };

  // Handler when options are removed
  const onRemove = (selectedList) => {
    setSelectedUsers(selectedList);
    setSearchText('');
  };

  const handleSelectSlot = (slot) => {
    setSelected(slot.start);
    setSelectedUsers([]);
    setSelectedSlot(slot);
    if (selectedUser) {
      const selectedUserIndex = participantList.find(
        (item) => item.id === selectedUser?.user_id
      );
      if (selectedUserIndex) {
        const dummyArray = [];
        dummyArray.push(selectedUserIndex);
        setSelectedUsers(dummyArray);
      }
    }
  };

  // Submit for Cash
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setIsSubmitLoading(true);
      setGeneralError('');
      const userIds = selectedUsers?.map((user) => user.id) ?? [];
      const response = await axiosInstance.post(
        BOOKING_ENDPOINT + `/${rangeId}`,
        {
          items: [
            {
              owner_class: LANE_CLASS,
              owner_id: laneId,
              quantity: 1,
              details: {
                participant_ids: userIds[1] ? [userIds[1]] : [],
                from_slot: selectedSlot?.start_unix_timestamp,
                to_slot: selectedSlot?.end_unix_timestamp,
              },
            },
          ],
          customer_id: userIds[0],
          payment_method_id: 2,
        }
      );

      if (response.data.code === 200) {
        navigate('/ranges/lane-check-list');
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
      setIsSubmitLoading(false);
    }
  };

  // Function for Stripe
  const handleStripeSubmit = async (event) => {
    event.preventDefault();
    Logger.log('handleStripeSubmit');
  };

  const handleTerminalSubmit = async (event) => {
    event.preventDefault();
    Logger.log('handleTerminalSubmit');
    // Call the Terminal API or perform any other necessary actions
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (activeTab === 'profile') {
      // Trigger Stripe Payment
      // await stripeRef.current.handlePayment();
      handleStripeSubmit(event);
    } else if (activeTab === 'home') {
      handleTerminalSubmit(event);
      // Trigger Terminal Payment
      // await terminalRef.current.handlePayment();
    } else {
      handleSubmit(event);
    }
  };

  const handleDeleteParticipant = async () => {
    try {
      setIsDeleteLoading(true);
      setGeneralError('');
      const response = await axiosInstance.delete(
        PARTICIPANT_ENDPOINT + `/${participant?.id}`
      );
      if (response.status === 204) {
        setParticipant({});
        setSendParticipant({});
        Toast.success('Participant deleted successfully.', {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsDeleteLoading(false);
    }
  };

  useEffect(() => {
    setLaneId('');
    setBayId('');
    setLaneList([]);
    setSlotList(null);
    setSelectedSlot({});
    setSelected('');
    setSelectedUsers([]);
    setSelectedBay({});
  }, [selectedDate]);

  const tabs = [
    {
      id: 'terminal',
      title: 'TERMINAL',
      icon: '../assets/images/credit-card-min.svg',
      content: (
        <div className="payment-card-tm">
          <div className="card-payment-ab">
            <h4 className="cash-price">
              ${selectedBay?.base_reservation_cost?.toFixed(2)}
            </h4>
            <div className="tickets">
              <input
                type="name"
                className="form-control input-theme"
                placeholder="Please Select location"
              />
            </div>
            <div className="tickets">
              <input
                type="name"
                className="form-control input-theme"
                placeholder="Please Select reader"
              />
            </div>
            <TerminalComponent />
          </div>
          <div className="pay-pay">
            <div className="button-range-create mt-5">
              <Link to="#" className="close-solcial-link-modal">
                <ButtonLoader
                  Heading="Book"
                  active="orange-outline"
                  bgChange="white-bg w-pd-add save-w"
                  type="submit"
                  // isLoading={isSubmitLoading}
                >
                  Book
                </ButtonLoader>
              </Link>
              <Link
                to="/ranges/lane-check-list"
                className="close-solcial-link-modal"
                data-bs-dismiss="modal"
                onClick={handleClose}
              >
                <ButtonRemoveImage
                  Heading="Cancel"
                  active="gary-light-outline"
                  bgChange="gary-bg w-pd-add"
                />
              </Link>
            </div>
          </div>
        </div>
      ),
      disabled: true,
    },
    {
      id: 'card',
      title: 'CARDS',
      icon: '../assets/images/stripe-com-min.svg',
      content: (
        <StripePayment
          selectedBay={selectedBay}
          handleStripeSubmit={handleStripeSubmit}
          ref={stripeRef}
        />
      ),
      disabled: true,
    },
    {
      id: 'cash',
      title: 'CASH',
      icon: '../assets/images/cash-payment-min.svg',
      content: (
        <div className="payment-card-tm">
          <div className="card-payment-ab">
            <h4 className="cash-price">
              ${selectedBay?.base_reservation_cost?.toFixed(2)}
            </h4>
          </div>
          <div className="pay-pay">
            <div className="button-range-create">
              <Link to="#" className="close-solcial-link-modal">
                <ButtonLoader
                  Heading="Book"
                  active="orange-outline"
                  bgChange="white-bg w-pd-add"
                  type="submit"
                  isLoading={isSubmitLoading}
                >
                  Book
                </ButtonLoader>
              </Link>
              <Link
                to="/ranges/lane-check-list"
                className="close-solcial-link-modal"
                data-bs-dismiss="modal"
                onClick={handleClose}
              >
                <ButtonRemoveImage
                  Heading="Cancel"
                  active="gary-light-outline"
                  bgChange="gary-bg w-pd-add"
                />
              </Link>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const handleUpdateUsers = (data) => {
    let dummyArray = [...selectedUsers];
    dummyArray.push(data);
    setSelectedUsers(dummyArray);
    multiselectRef.current.resetSelectedValues();
    multiselectRef.current.setSelectedValues(dummyArray);
  };

  return (
    <>
      {isDeleteLoading && <LoadingOverlayMain />}
      <div className="outer-border outer-over">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <div className="back-listing-tab">
                    <Link to="/ranges/lane-check-list" className="button-width">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <h3 className="card-top-2 h-set-mob">Book Slot</h3>
                  </div>
                  <div className="all-date-selected new-back-btn-add">
                    <Link
                      to="/ranges/lane-check-list"
                      className="button-width b-p"
                    >
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text book-slot-w">
                    <form onSubmit={handleFormSubmit}>
                      <div className="">
                        <Calendar
                          selectedDate={selectedDate}
                          setSelectedDate={setSelectedDate}
                        />

                        <div className="create-slots book-slots">
                          <div className="select-bays">
                            <div className="select-input tickets">
                              <select
                                className="form-select mb-0"
                                disabled={isLoading || bayList?.length === 0}
                                onChange={(e) =>
                                  handleChangeBay(e.target.value)
                                }
                                value={bayId}
                                required
                              >
                                <option value="">Bay&lsquo;s</option>
                                {bayList?.map((item, key) => (
                                  <option value={item.id} key={key}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="select-input tickets d-none">
                              <select
                                className="form-select mb-0"
                                disabled={isLoading || laneList?.length === 0}
                                onChange={(e) =>
                                  handleChangeLane(e.target.value)
                                }
                              >
                                <option value="">Lane</option>
                                {laneList?.map((item, key) => (
                                  <option
                                    value={item.id}
                                    key={key}
                                    disabled={item.is_open === 0 ? true : false}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="add-guest-price">
                            {selectedBay?.id && (
                              <>
                                <p>
                                  Guest Price :
                                  <span>
                                    $
                                    {selectedBay?.base_reservation_cost.toFixed(
                                      2
                                    )}
                                  </span>
                                </p>
                                <p>
                                  Member Price :
                                  <span>
                                    $
                                    {selectedBay?.member_reservation_cost.toFixed(
                                      2
                                    )}
                                  </span>
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 d-none">
                            <div className="table-responsive w-100">
                              <table className="table table-user-gan table-blocker">
                                <thead>
                                  <tr>
                                    <th>Bay</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="bg-color-theme">1</td>
                                  </tr>
                                  <tr>
                                    <td className="bg-color-red">2</td>
                                  </tr>
                                  <tr>
                                    <td className="bg-color-theme">3</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {laneList?.length > 0 && (
                            <div className="col-md-6">
                              <div className="a-and-n justify-content-end">
                                <div className="empty">
                                  <p>Empty</p>
                                </div>
                                <div className="reserved">
                                  <p>Reserved</p>
                                </div>
                                <div className="choice">
                                  <p>Current Selection</p>
                                </div>
                                <div className="booked">
                                  <p>Closed</p>
                                </div>
                              </div>
                              <div className="active-not-active-range">
                                <p className="range-heading">LANE</p>
                                <ul>
                                  {laneList?.map((item, index) => (
                                    <li
                                      key={index}
                                      className={
                                        item.is_open !== 0
                                          ? laneId === item.id
                                            ? 'bg-org'
                                            : 'bg-black-lane' //bg-gray
                                          : 'bg-red'
                                      }
                                      onClick={() =>
                                        item.is_open !== 0
                                          ? handleChangeLane(item.id)
                                          : null
                                      }
                                    >
                                      <img
                                        src="../assets/icon/range_target.svg"
                                        alt=""
                                      />
                                      {item.name}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                        {laneId && (
                          <div className="time-select-bays-and-lane">
                            {slotList?.length > 0 && (
                              <div className="custom-radio-group">
                                {slotList.map((slot, index) => (
                                  <label
                                    key={index}
                                    className={`custom-radio  ${slot.booked ? ' disabled-slot-option ' : ''} ${selected === slot.start ? 'checked ' : ''}`}
                                  >
                                    <input
                                      type="radio"
                                      name="slot"
                                      value={slot.start}
                                      checked={selected === slot.start}
                                      onChange={() => handleSelectSlot(slot)}
                                      disabled={slot.booked}
                                    />
                                    <span className="custom-radio-label">
                                      {slot.start} - {slot.end}
                                    </span>
                                  </label>
                                ))}
                              </div>
                            )}
                            {slotList?.length === 0 && (
                              <span className="text-danger fw-bolder d-flex justify-content-center">
                                Slot Not Available.
                              </span>
                            )}
                          </div>
                        )}

                        {Object.keys(selectedSlot).length > 0 && (
                          <div className="">
                            <div className="row mt-5">
                              {Object.keys(selectedSlot).length > 0 && (
                                <>
                                  <div className="col-lg-6 mt-4 mt-lg-0">
                                    <Multiselect
                                      ref={multiselectRef}
                                      isObject={true}
                                      options={participantList}
                                      selectedValues={selectedUsers}
                                      showCheckbox
                                      onSelect={onSelect}
                                      onRemove={onRemove}
                                      displayValue="displayValue"
                                      onSearch={(text) => setSearchText(text)}
                                      selectionLimit={2}
                                    />
                                    <div className="user-count-select">
                                      {selectedUsers?.map((item, index) => (
                                        <div className="user-box" key={index}>
                                          <p>{item?.displayValue} </p>
                                          {/* {index === 0 && <p> CUSTOMER </p>} */}
                                          <p className="cr-s-i">
                                            <img
                                              src="../assets/icon/cross-svgrepo-com.svg"
                                              alt=""
                                              onClick={() => {
                                                setSelectedUsers(
                                                  selectedUsers.filter(
                                                    (currentItem) =>
                                                      currentItem !== item
                                                  )
                                                );
                                              }}
                                            />
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    {participant?.id && (
                                      <div className="sign-in user-id-profile-list">
                                        <div className="triangle-card sign-in-card">
                                          <div className="user-confirm">
                                            <Link
                                              data-bs-toggle="modal"
                                              data-bs-target="#exampleModal"
                                              onClick={() =>
                                                setSendParticipant(participant)
                                              }
                                            >
                                              <img
                                                src="../assets/icon/edit.svg"
                                                alt=""
                                              />
                                            </Link>
                                            <Link
                                              onClick={handleDeleteParticipant}
                                            >
                                              <img
                                                src="../assets/icon/delete.svg"
                                                alt=""
                                              />
                                            </Link>
                                          </div>
                                          <h4>{participant?.name}</h4>
                                          <p>{participant?.username}</p>
                                          <p>{participant?.email}</p>
                                          <p>
                                            {moment(participant?.dob).format(
                                              'DD/MM/YYYY'
                                            )}
                                          </p>
                                          <p>{participant?.phone_number}</p>
                                          <p>{participant?.gender}</p>
                                        </div>
                                      </div>
                                    )}

                                    {selectedUsers?.length < 2 && (
                                      <div className="participants-button">
                                        <Link
                                          to="#"
                                          className="button-width"
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                        >
                                          <ButtonAdd
                                            images="../assets/icon/plaus_icon.svg"
                                            Heading="ADD Participants"
                                            active="orange-outline"
                                            bgChange="white-bg"
                                          />
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                              {selectedUsers?.length > 0 && (
                                <div className="col-12 mt-5">
                                  <ul
                                    className="nav nav-tabs payment-card"
                                    id="myTab"
                                    role="tablist"
                                  >
                                    {tabs.map((tab) => (
                                      <li
                                        key={tab.id}
                                        className="nav-item"
                                        role="presentation"
                                      >
                                        <div
                                          className={`custom-action-button  ${tab.disabled ? 'dis-btn-tab' : ''} `}
                                          id={`${tab.id}-tab`}
                                          // data-bs-toggle="tab"
                                          // data-bs-target={`#${tab.id}`}
                                          type="button"
                                          role="tab"
                                          aria-controls={
                                            !tab.disabled && tab.id
                                          }
                                          aria-selected={
                                            tab.disabled
                                              ? false
                                              : activeTab === tab.id
                                          }
                                          onClick={() =>
                                            !tab.disabled &&
                                            setActiveTab(tab.id)
                                          }
                                          {...(!tab.disabled && {
                                            'data-bs-toggle': 'tab',
                                            'data-bs-target': `#${tab.id}`,
                                          })}
                                        >
                                          <button
                                            type="button"
                                            className={`nav-link ${activeTab === tab.id ? 'active' : ''}  bgChange`}
                                            disabled={tab.disabled}
                                          >
                                            <span>
                                              <img src={tab.icon} alt="" />
                                            </span>
                                            <span>{tab.title}</span>
                                          </button>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                  <div
                                    className="tab-content mt-5 "
                                    id="myTabContent"
                                  >
                                    {tabs.map((tab) => (
                                      <div
                                        key={tab.id}
                                        className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`}
                                        id={tab.id}
                                        role="tabpanel"
                                        aria-labelledby={`${tab.id}-tab`}
                                      >
                                        {tab.content}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                      {generalError && (
                        <p className="error text-center">{generalError}</p>
                      )}

                      {/* {selectedUsers?.length > 0 &&
                        Object.keys(selectedSlot).length > 0 && (
                          <div className="">
                            <div className="button-range-create mt-5">
                              <ButtonLoader
                                Heading="Book"
                                active="orange-outline"
                                bgChange="white-bg w-pd-add save-w"
                                type="submit"
                                isLoading={isSubmitLoading}
                              >
                                Book
                              </ButtonLoader>
                              <Link
                                to="#"
                                className="close-solcial-link-modal"
                                data-bs-dismiss="modal"
                                onClick={handleClose}
                              >
                                <ButtonRemoveImage
                                  Heading="Cancel"
                                  active="gary-light-outline"
                                  bgChange="gary-bg w-pd-add"
                                />
                              </Link>
                            </div>
                          </div>
                        )} */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SlotForm
        fetchParticipantList={fetchParticipantList}
        handleUpdateUsers={handleUpdateUsers}
        participant={sendParticipant}
      />
    </>
  );
};

export default BookSlotUserAdd;
