import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ButtonAdd from './ButtonAdd';
import CreateRangesModal from './CreateRangesModal';
import { clearItem, getItem, saveItem } from './../Utils/LocalStorage';
import LoadingOverlayMain from './LoadingOverlayMain';
import { useDispatch, useSelector } from 'react-redux';
import Logger from '../Utils/Logger';
import { clearRangeData } from '../Redux/features/ranges/rangeSlice';
import { truncateString } from '../Utils/common';

const Header = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);
  const [expandedSubMenu, setExpandedSubMenu] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRange, setSelectedRange] = useState({});
  const [isLoadingRangeChange, setIsLoadingRangeChange] = useState(false);
  const rangeList = useSelector((state) => state.range.rangeList);
  const dispatch = useDispatch();
  const prevPathname = useRef(null);

  useEffect(() => {
    setActiveItem(location.pathname);
    if (location.pathname.includes('ranges')) {
      setExpandedSubMenu('submenu2');
    } else if (location.pathname.includes('upcoming')) {
      setExpandedSubMenu('submenu3');
    } else if (location.pathname.includes('accounts')) {
      setExpandedSubMenu('submenu4');
    } else if (location.pathname.includes('store')) {
      setExpandedSubMenu('submenu5');
    } else {
      setExpandedSubMenu('');
    }
  }, [location.pathname]);

  const handleSubMenuToggle = (subMenu) => {
    setExpandedSubMenu((prev) => (prev === subMenu ? subMenu : subMenu));
  };

  useEffect(() => {
    fetchRangeList();
  }, [rangeList]);

  // useEffect(() => {
  //   if (
  //     prevPathname.current === '/upcoming/private-training' &&
  //     location.pathname !== '/upcoming/private-training'
  //   ) {
  //     dispatch(clearRangeData());
  //   }
  //   prevPathname.current = location.pathname;
  // }, [location, dispatch]);

  useEffect(() => {
    if (prevPathname.current && prevPathname.current !== location.pathname) {
      dispatch(clearRangeData());
      clearItem('classData');
    }
    prevPathname.current = location.pathname;
  }, [location, dispatch]);

  const fetchRangeList = async () => {
    try {
      setIsLoading(true);
      const localRange = getItem('rangeId');
      if (rangeList?.length > 0) {
        if (localRange) {
          const selectedItem = rangeList.find((item) => item.id === localRange);
          setSelectedRange(selectedItem);
        } else {
          setSelectedRange(rangeList[0]);
          saveItem('rangeId', rangeList[0]?.id);
        }
      } else {
        setSelectedRange({});
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedRange = (range) => {
    setIsLoadingRangeChange(true);
    setSelectedRange(range);
    saveItem('rangeId', range?.id);
    setTimeout(() => {
      setIsLoadingRangeChange(false);
      window.location.reload();
    }, 600);
  };

  return (
    <>
      {isLoadingRangeChange && <LoadingOverlayMain />}
      <header>
        {show ? <div className="overlay side-bar"></div> : null}
        <input type="checkbox" id="checked" className="d-none" />
        <label
          htmlFor="checked"
          className="click"
          onClick={() => setShow(!show)}
        >
          <i className="fa fa-align-justify" aria-hidden="true"></i>
        </label>
        <nav className="navbar-bg">
          <Link to="/dashboard" className="logo-dashboard">
            <img
              src="../assets/images/logo.png"
              alt="logo.png"
              className="nav-logo"
            />
          </Link>
          <div className="dropdown header-drop-down d-block d-xl-none">
            <div
              className="pin"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle={rangeList?.length > 1 ? 'dropdown' : null}
              aria-expanded="false"
            >
              <button
                className={
                  rangeList?.length === 0 &&
                  Object.keys(selectedRange).length === 0
                    ? 'drop-bg-color'
                    : rangeList?.length > 1
                      ? 'dropdown-toggle city-name'
                      : ''
                }
              >
                {!isLoading && Object.keys(selectedRange).length > 0 && (
                  <p className="range-name">
                    {truncateString(selectedRange?.name, 18)} -{' '}
                    <span>{selectedRange?.address?.city}</span>
                  </p>
                )}
              </button>
            </div>
            {rangeList?.length > 1 && (
              <ul
                className="dropdown-menu another sub-menu-list"
                aria-labelledby="dropdownMenuButton1"
              >
                {rangeList?.length > 0 &&
                  rangeList.map((range, index) => (
                    <li key={index} onClick={() => handleSelectedRange(range)}>
                      <Link className="dropdown-item" to="#">
                        {truncateString(range?.name, 18)} -{' '}
                        <span>{range?.address?.city}</span>
                      </Link>
                    </li>
                  ))}
              </ul>
            )}
          </div>
          <ul className="listing-bar">
            <li className={activeItem === '/dashboard' ? 'active-border' : ''}>
              <Link
                to="/dashboard"
                className={
                  activeItem === '/dashboard'
                    ? 'active-color side-nav-icon nav-link '
                    : 'side-nav-icon nav-link '
                }
              >
                Dashboard
              </Link>
            </li>
            <li className={activeItem === '/today' ? 'active-border' : ''}>
              <Link
                to="/today"
                className={activeItem === '/today' ? 'active-color' : ''}
              >
                Today
              </Link>
            </li>
            <li
              className={expandedSubMenu === 'submenu2' ? 'active-border' : ''}
            >
              <Link
                to="#"
                className={
                  activeItem.startsWith('/ranges')
                    ? 'active-color nav-link collapsed side-nav-icon'
                    : 'nav-link collapsed side-nav-icon'
                }
                data-toggle="collapse"
                data-target="#submenu2"
              >
                Ranges
              </Link>
              <ul
                className={`collapse ${
                  expandedSubMenu === 'submenu2' ? 'show' : ''
                }`}
                id="submenu2"
                onClick={() => handleSubMenuToggle('submenu2')}
                // aria-expanded={expandedSubMenu === "submenu2"}
              >
                <li className="input-search">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img src="../assets/icon/search_icon.svg" alt="" />
                </li>
                <li>
                  <Link
                    to="/ranges/range-locations"
                    className={
                      activeItem === '/ranges/range-locations'
                        ? // ||  activeItem.includes('/ranges/range-locations')
                          'active-color'
                        : ''
                    }
                  >
                    <span className="img-icon">
                      <img
                        src="../assets/icon/Range-location.png"
                        alt=""
                        style={{ width: '22px', height: '33px' }}
                      />
                    </span>
                    <span>Range Locations</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ranges/user-details"
                    className={
                      activeItem === '/ranges/user-details'
                        ? 'active-color'
                        : ''
                    }
                  >
                    <span className="img-icon">
                      <img
                        src="../assets/icon/fpe-validation.png"
                        alt=""
                        style={{ height: '22px', transform: 'translateY(4px)' }}
                      />
                    </span>
                    <span>FPE Validation</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ranges/id-verification-list"
                    className={
                      activeItem === '/ranges/id-verification-list'
                        ? 'active-color'
                        : ''
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/id_verification.svg" alt="" />
                    </span>
                    <span>ID Verification</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ranges/lane-check-list"
                    className={
                      activeItem === '/ranges/lane-check-list'
                        ? 'active-color'
                        : ''
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/lane_checkin.svg" alt="" />
                    </span>
                    <span>Lane CheckIn</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className={
                      activeItem === '#' ? 'active-color' : 'point-evn'
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/event_checkin.svg" alt="" />
                    </span>
                    <span>Class CheckIn</span>
                  </Link>
                </li>

                <li>
                  <Link
                    to="/ranges/range-activities"
                    className={
                      activeItem === '/ranges/range-activities'
                        ? 'active-color'
                        : ''
                    }
                  >
                    <span className="img-icon">
                      <img
                        src="../assets/icon/range-activities.png"
                        alt=""
                        style={{ height: '31px', width: '35px' }}
                      />
                    </span>
                    <span>Range Activities</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ranges/booking"
                    className={
                      activeItem === '/ranges/booking' ? 'active-color' : ''
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/Bookings.png" alt="" />
                    </span>
                    <span>Booking</span>
                  </Link>
                </li>
                <li className="create-page mb-3">
                  <Link
                    to="/ranges/create"
                    // data-bs-toggle="modal"
                    // data-bs-target="#createRanges"
                  >
                    <ButtonAdd
                      Heading="ADD"
                      images="../assets/icon/plaus_icon.svg"
                      bgChange="bg-dark-black"
                      active="orange-outline"
                    />
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={expandedSubMenu === 'submenu3' ? 'active-border' : ''}
            >
              <Link
                to="#"
                className={
                  activeItem.startsWith('/upcoming')
                    ? 'active-color nav-link collapsed side-nav-icon'
                    : 'nav-link collapsed side-nav-icon'
                }
                data-toggle="collapse"
                data-target="#submenu3"
              >
                Upcoming
              </Link>
              <ul
                className={`collapse ${
                  expandedSubMenu === 'submenu3' ? 'show' : ''
                }`}
                onClick={() => handleSubMenuToggle('submenu3')}
                id="submenu3"
                // aria-expanded={expandedSubMenu === "submenu3"}
              >
                <li className="input-search">
                  <input
                    type="text"
                    autoComplete="false"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img src="../assets/icon/search_icon.svg" alt="" />
                </li>
                <li>
                  <Link
                    to="/upcoming/up-coming"
                    className={
                      activeItem === '/upcoming/up-coming' ? 'active-color' : ''
                    }
                  >
                    <span className="img-icon">
                      <img
                        src="../assets/icon/lane_booking_active1.svg"
                        alt=""
                      />
                    </span>
                    <span>Lane Bookings</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/upcoming/class"
                    className={
                      activeItem === '/upcoming/class' ? 'active-color' : ''
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/classes.svg" alt="" />
                    </span>
                    <span>Classes</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className={
                      activeItem === '#' ? 'active-color' : 'point-evn'
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/private_training.svg" alt="" />
                    </span>
                    <span>Private Training</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/upcoming/events"
                    className={
                      activeItem === '/upcoming/events'
                        ? 'active-color'
                        : 'point-evn'
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/events.svg" alt="" />
                    </span>
                    <span>Events</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className={
                      activeItem === '#' ? 'active-color' : 'point-evn'
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/lane_checkin.svg" alt="" />
                    </span>
                    <span>Experiences</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className={
                      activeItem === '#' ? 'active-color' : 'point-evn'
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/canine_training.svg" alt="" />
                    </span>
                    <span>Canine Training</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className={
                      activeItem === '#' ? 'active-color' : 'point-evn'
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/building_rental.svg" alt="" />
                    </span>
                    <span>Facility Rental</span>
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/upcoming/event-categories"
                    className={
                      activeItem === '/upcoming/event-categories'
                        ? 'active-color'
                        : ''
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/private_training.svg" alt="" />
                    </span>
                    <span>Categories</span>
                  </Link>
                </li> */}
                <li className="pb-3">
                  <Link
                    to="/upcoming/attendees"
                    className={
                      activeItem === '/upcoming/attendees'
                        ? 'active-color'
                        : 'point-evn'
                    }
                  >
                    <span className="img-icon">
                      <img
                        src="../assets/icon/user.svg"
                        alt=""
                        className="attendees-img"
                      />
                    </span>
                    <span>Attendees</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={expandedSubMenu === 'submenu4' ? 'active-border' : ''}
            >
              <Link
                to="#"
                className={
                  activeItem.startsWith('/accounts')
                    ? 'active-color nav-link collapsed side-nav-icon'
                    : 'nav-link collapsed side-nav-icon'
                }
                data-toggle="collapse"
                data-target="#submenu4"
              >
                Accounts
              </Link>
              <ul
                className={`collapse ${
                  expandedSubMenu === 'submenu4' ? 'show' : ''
                }`}
                onClick={() => handleSubMenuToggle('submenu4')}
                id="submenu4"
                // aria-expanded={expandedSubMenu === "submenu4"}
              >
                <li className="input-search">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img src="../assets/icon/search_icon.svg" alt="" />
                </li>
                <li>
                  <Link
                    to="/accounts/participants"
                    className={
                      activeItem === '/accounts/participants'
                        ? 'active-color'
                        : ''
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/participants.svg" alt="" />
                    </span>
                    <span>Participants</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/accounts/guests"
                    className={
                      activeItem === '/accounts/guests' ? 'active-color' : ''
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/guests.svg" alt="" />
                    </span>
                    <span>Guests</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/accounts/account"
                    className={
                      activeItem === '/accounts/account' ? 'active-color' : ''
                    }
                  >
                    <span className="img-icon">
                      <img
                        src="../assets/icon/memberships_active.svg"
                        alt=""
                        className="stat-icon"
                      />
                    </span>
                    <span>Memberships</span>
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="#"
                    className={
                      activeItem === '#' ? 'active-color' : 'point-evn'
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/rtb_arms.svg" alt="" />
                    </span>
                    <span>Right to Bear Arms</span>
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="/accounts/plans"
                    className={
                      activeItem === '/accounts/plans' ? 'active-color' : ''
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/images/align.png" alt="" />
                    </span>
                    <span>Plans</span>
                  </Link>
                </li>
                <li className="create-page mb-3">
                  <Link
                    to="#"
                    className={activeItem === '#' ? 'active-color' : ''}
                  >
                    <ButtonAdd
                      Heading="ADD"
                      images="../assets/icon/plaus_icon.svg"
                      bgChange="bg-dark-black"
                      active="orange-outline"
                    />
                  </Link>
                </li>
              </ul>
            </li>
            <li className={activeItem === '/instructor' ? 'active-border' : ''}>
              <Link
                to="/instructor"
                className={activeItem === '/instructor' ? 'active-color' : ''}
              >
                Instructor
              </Link>
            </li>
            <li
              className={expandedSubMenu === 'submenu5' ? 'active-border' : ''}
            >
              <Link
                to="#"
                className={
                  activeItem.startsWith('/store')
                    ? 'active-color nav-link collapsed side-nav-icon'
                    : 'nav-link collapsed side-nav-icon'
                }
                data-toggle="collapse"
                data-target="#submenu5"
              >
                Store
              </Link>
              <ul
                className={`collapse ${
                  expandedSubMenu === 'submenu5' ? 'show' : ''
                }`}
                onClick={() => handleSubMenuToggle('submenu5')}
                id="submenu5"
                // aria-expanded={expandedSubMenu === "submenu5"}
              >
                <li>
                  <Link
                    to="/store/orders"
                    className={
                      activeItem === '/store/orders' ? 'active-color' : ''
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/orders.png" alt="" />
                    </span>
                    <span>Orders</span>
                  </Link>
                </li>
                <li className="pb-3">
                  <Link
                    to="/store/products"
                    className={
                      activeItem === '/store/products' ? 'active-color' : ''
                    }
                  >
                    <span className="img-icon">
                      <img src="../assets/icon/products.png" alt="" />
                    </span>
                    <span>Products</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={activeItem === '/user-profile' ? 'active-border' : ''}
            >
              <Link
                to="/user-profile"
                className={activeItem === '/user-profile' ? 'active-color' : ''}
              >
                Profile
              </Link>
            </li>
          </ul>
        </nav>
      </header>
      {location?.pathname !== '/ranges/range-locations' && (
        <CreateRangesModal />
      )}
    </>
  );
};

export default Header;
